// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS SYNDIC  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-admin-syndic',
    redirect: '/espace-admin-syndic/dashboard',
    component: () => import('@/layouts/espace-admin-syndic/AdminSyndicLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.role === 'Administrateur syndic') next()
      else next({ name: 'error-401' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-admin-syndic/dashboard',
        name: 'espace-admin-syndic.dashboard',

        component: () => import('@/views/app/espace-admin-syndic/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

        },
      },
      // Propriétés Actifs
      {
        path: '/espace-admin-syndic/proprietes',
        name: 'espace-admin-syndic.proprietes',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/Index.vue'),

        meta: {
          pageTitle: 'Liste des biens immobiliers actifs',
          redirectIfLoggedIn: true,

        },
      },
      // Propriétés Suspendues
      {
        path: '/espace-admin-syndic/proprietes',
        name: 'espace-admin-syndic.proprietes-suspendues',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/ProprietesSuspendues.vue'),

        meta: {
          pageTitle: 'Liste des biens immobiliers suspendus',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-admin-syndic/:id/detail-propriete',
        name: 'espace-admin-syndic.detail-propriete',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/Detail.vue'),

        meta: {
          pageTitle: 'Détail du bien immobilier',
          redirectIfLoggedIn: true,

        },
      },
      // Ajout Immeuble
      {
        path: '/espace-admin-syndic/creer-immeuble',
        name: 'espace-admin-syndic.creer-immeuble',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/CreateImmeuble.vue'),

        meta: {
          pageTitle: 'Création d\'un immeuble',
          redirectIfLoggedIn: true,

        },
      },
      // Modifier Immeuble
      {
        path: '/espace-admin-syndic/:id/modifier-immeuble',
        name: 'espace-admin-syndic.modifier-immeuble',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/ModifyImmeuble.vue'),

        meta: {
          pageTitle: 'Modification d\'un immeuble',
          redirectIfLoggedIn: true,

        },
      },
      // Ajout Villa
      {
        path: '/espace-admin-syndic/creer-villa',
        name: 'espace-admin-syndic.creer-villa',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/CreateVilla.vue'),

        meta: {
          pageTitle: 'Création d\'une villa',
          redirectIfLoggedIn: true,

        },
      },
      // Modifier Villa
      {
        path: '/espace-admin-syndic/:id/modifier-villa',
        name: 'espace-admin-syndic.modifier-villa',

        component: () => import('@/views/app/espace-admin-syndic/proprietes/ModifyVilla.vue'),

        meta: {
          pageTitle: 'Modification d\'une villa',
          redirectIfLoggedIn: true,

        },
      },
      // Staff
      {
        path: '/espace-admin-syndic/staff',
        name: 'espace-admin-syndic.staff',

        component: () => import('@/views/app/espace-admin-syndic/utilisateurs/Staff.vue'),

        meta: {
          pageTitle: 'Membres de la copropriété',
          redirectIfLoggedIn: true,

        },
      },
      // Détail utilisateur
      {
        path: '/espace-admin-syndic/:id/detail-utilisateur',
        name: 'espace-admin-syndic.detail-utilisateur',

        component: () => import('@/views/app/espace-admin-syndic/utilisateurs/Detail.vue'),

        meta: {
          pageTitle: 'Profil utilisateur',
          redirectIfLoggedIn: true,

        },
      },
      // Copropriété
      {
        path: '/espace-admin-syndic/copropriete',
        name: 'espace-admin-syndic.copropriete',

        component: () => import('@/views/app/espace-admin-syndic/coproprietes/Index.vue'),

        meta: {
          pageTitle: 'Ma copropriété',
          redirectIfLoggedIn: true,
        },
      },
      // Copropriété
      {
        path: '/espace-admin-syndic/frais-de-copropriete',
        name: 'espace-admin-syndic.frais-copropriete',

        component: () => import('@/views/app/espace-admin-syndic/coproprietes/FraisCopropriete.vue'),

        meta: {
          pageTitle: 'Frais de copropriété',
          redirectIfLoggedIn: true,
        },
      },
    ],
  },
]
